.pen {
    &--floating {
        font-size: 0.70em;
    }
}

.plus {
    &--floating {
        font-size: 0.75em;
    }
}