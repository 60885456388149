@import '../../globals/scss/variables';

.book-detail {
  margin: 0 auto;
  padding: em(30) 0;
  @media (min-width: map_get($grid-breakpoints, 'md')) {
    padding: em(60) 0;
    max-width: 70%;
  }
  &__header {
    @media (min-width: map_get($grid-breakpoints, 'md')) {
      display: flex;
    }
  }
  &__image-wrapper {
    width: 100%;
    margin-bottom: 1rem;
    @media (min-width: map_get($grid-breakpoints, 'md')) {
      max-width: 30%;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
  }
  &__image {
    max-width: 100%;
  }
  &__title {
    line-height: 1.1;
  }
  &__tags {
    display: flex;
    margin-top: 0.5rem;
    @media (min-width: map_get($grid-breakpoints, 'md')) {
      flex-wrap: wrap;
    }
  }
  &__tag {
    background: $primary-color;
    border-radius: 99px;
    color: #fff;
    padding: 0.25rem 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &__description {
    margin-top: 1rem;
  }
}