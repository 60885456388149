@import '../../globals/scss/variables';

.library-item {
    list-style: none;
    font-weight: 700;
    font-size: em(22);
    width: 100%;
    border-bottom: 1px solid $light-grey;
    padding: em(5) 0;
    margin-bottom: em(10);
    &:last-child {
        margin-bottom: 0;
	}
	&__menu {
		&__button {
			transition: all 0.4s ease;
			margin-right: em(10);
			&:last-child {
				margin-right: 0;
			}
		}
	}
    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
            .library-item__label {
                color: $blue;
			}
			.library-item__menu {
				&__button {
					color: $blue;
				}
				color: $blue;
			}
        }
    }
    &__label {
        transition: all 0.4s ease;
        display: flex;
        align-items: center;
	}
	&__input {
		border: none;
		padding: 0;
		font-weight: 700;
		&:focus {
			outline: none;
		}
	}
}