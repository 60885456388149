@import '../../globals/scss/variables';

.login-view {
    &__form-wrapper {
        background: #fff;
        box-shadow:$box-shadow-button;
        border-radius: 30px;
        width: 50%;
        max-width: 450px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
    }   

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        padding-top: 80px;
        max-width: 450px;
        margin: 0 auto;
        &__h1 {
            line-height: 1;
        }
        svg {
            color: #fff;
            width: 80px !important;
            height: auto;
        }
    }
}