@import '../../globals/scss/variables';

.library-list {
    max-width: 70%;
    padding: em(60) 0;
    margin: 0 auto;

    &__ul {
        margin: em(10) 0 0 0;
        padding: 0;
    }

    &__edit-button {
        display: flex;
        align-items: center;   
    }
}