@import '../../globals/scss/variables';

.input-group {
    width: 100%;
    &__label {
        width: 100%;
        position: relative;
        margin-top: 13px;
        margin-bottom: 20px;
    }

    &__label-text {
        position: absolute;
        color: $grey;
        font-weight: 300;
        font-size: 1.1em;
        top: -4px;
        transition: all 0.4s ease;
        &--error {
            color: $red !important;
        }
        &--focus {
            color: #333;
            font-size: 0.8em;
            top: -18px;
        }
    }

    &__input {
        width: 100%;
        background: none;
        border: none;
        border-bottom: 2px solid $grey;
        transition: all 0.4s ease;
        &--error {
            border-bottom: 2px solid $red;
        }
        &:focus {
            outline: none;
            border-bottom: 2px solid #333;
        }
    }
}
