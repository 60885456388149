@import '../../globals/scss/variables';

.book-item {
  list-style: none;
  margin-bottom: 2.5rem;
  &__title {
    font-size: 1.375rem;
    font-weight: 700;
  }
  &__image {
    max-width: 120px;
  }
  &__link {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &:hover {
      color: $blue;
    }
  }
  &__info {
    margin-left: 1rem;
  }
}