@import '../../globals/scss/variables';

.library-add-button {
    display: block;
    text-align: center;
    line-height: 4em;
    &:hover {
        color: #fff;
    }
}

.button-menu {
    position: fixed;
    display: flex;
    bottom: em(16);
    right: em(16);
    @media (min-width: map_get($grid-breakpoints, 'md')) {
        bottom: em(80);
        right: em(80);
    }
    &__item {
        margin-right: em(15);
        &:last-child {
            margin-right: 0;
        }
    }
}