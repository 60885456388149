*:not(svg):not(path):not(g) {
    font-family: $primary-font-family;
    color: $primary-font-color;
}

.h1 {
    font-size: 3em;
    font-weight: 800;
    &--white {
        color: #fff;
    }
}

.h2 {
    font-weight: 800;
}

.h3 {
    font-size: 1em;
}

.p {
    font-weight: 300;
}

.b {
	font-weight: 700;
}

.a {
    font-weight: 300;
    color: $blue;
    transition: all 0.4s ease;
    &:hover {
        color: $blue-darker;
        text-decoration: none;
    }
    &--black {
        color: #333;
        &:hover {
            color: $blue;
        }
    }

    &--bold {
        font-weight: 700;
    }
}

.reset-button {
	background: 0;
	padding: 0;
	margin: 0;
	border: 0;
	&:focus {
		outline: none;
	}
}

.light {
    font-weight: 100;
    &--white {
        color: #fff;
    }
}

.app {
    width: 100%;
    min-height: 100vh;
    background: url(../img/background.svg) fixed;
    background-size: cover;

    &--smaller-padding {
        padding-top: em(20);
        @media (min-width: map_get($grid-breakpoints, 'md')) {
            padding-top: em(100);
        }
    }
}

.container {
    @include max-width-container;
    background: #fff;
    border-top-left-radius: em(50);
    border-top-right-radius: em(50);
    position: relative;

    &__inner {
        max-width: 50%;
        padding: em(60) 0;
        margin: 0 auto;
    }
}

