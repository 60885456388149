@import '../../globals/scss/variables.scss';

.library-item {
	&__label {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		&__count {
			font-size: em(12);
			font-weight: 300;
		}
	}
}

.shelf-menu  {
	&__button {
		font-size: em(24);
	}
}