@import '../../globals/scss/variables';

.btn {
    background: $primary-color;
    border: none;
    color: #fff;
    transition: all 0.4s ease;
    display: inline-flex;
    align-items: center;
    border-radius: 99px;
    padding: 0.25rem 0.75rem;
    &:focus {
        outline: none;
    }
    &:hover {
        background: darken($primary-color, 10%);
        text-decoration: none;
        color: #fff;
    }
    &:disabled {
        background: $grey;
    }
    &--clear {
        color: $primary-font-color;
        background: none;
        padding: 0;
        &:hover {
            background: none;
            color: $blue;
        }
    }

    &--floating {
        border-radius: 100%;
        box-shadow: $box-shadow-button;
        width: 50px;
        height: 50px;
        svg {
            vertical-align: middle;
        }
    }
    & > svg {
        margin-right: 0.5rem;
    }
}